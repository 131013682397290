import { FC } from 'react';
import { ChatWidgetInfo } from 'src/models/ChatMessage';

export enum WidgetType {
  VIEW_TIMEOFF_BALANCES = 'VIEW_TIMEOFF_BALANCES',
  VIEW_TIMEOFF_REQUESTS = 'VIEW_TIMEOFF_REQUESTS',
  VIEW_PUNCHES = 'VIEW_PUNCHES',
  VIEW_UPT_BALANCE = 'VIEW_UPT_BALANCE',
  VIEW_UPT_HISTORY = 'VIEW_UPT_HISTORY',
  VIEW_SCHEDULE = 'VIEW_SCHEDULE',
  LIVE_SUPPORT = 'LIVE_SUPPORT',
  LIVE_SUPPORT_CALL_BACK = 'LIVE_SUPPORT_CALL_BACK',
  WORKFLOW_ACTION_COMPLETED = 'WORKFLOW_ACTION_COMPLETED',
}

export interface WidgetViewProps {
  employeeId: string;
  widgetInfo: ChatWidgetInfo;
  employeeInfo: EmployeeInfo;
  contactId: string;
}

export type WidgetView = FC<WidgetViewProps>;
