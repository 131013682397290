import { WidgetType, WidgetView } from 'src/models/WidgetView';
import WorkflowActionCompletedView from 'src/components/Widgets/WorkflowActionCompletedView';
import PunchView from 'src/components/Widgets/PunchView';
import TimeOffBalancesView from 'src/components/Widgets/TimeOffBalancesView';
import TimeoffRequestsView from 'src/components/Widgets/TimeoffRequestsView';
import ScheduleView from 'src/components/Widgets/ScheduleView';
import AttendanceBalanceView from 'src/components/Widgets/attendance/AttendanceBalanceView';
import AttendanceEventHistory from 'src/components/Widgets/attendance/AttendanceEventHistory';
import LiveSupportOption from 'src/components/Widgets/supportOptions/LiveSupportOption';
import RequestCallBack from 'src/components/Widgets/supportOptions/RequestCallBack';

export const WIDGET_CONFIG_MAP: Record<WidgetType, WidgetView> = {
  [WidgetType.VIEW_PUNCHES]: PunchView,
  [WidgetType.VIEW_TIMEOFF_BALANCES]: TimeOffBalancesView,
  [WidgetType.VIEW_TIMEOFF_REQUESTS]: TimeoffRequestsView,
  [WidgetType.VIEW_SCHEDULE]: ScheduleView,
  [WidgetType.VIEW_UPT_BALANCE]: AttendanceBalanceView,
  [WidgetType.VIEW_UPT_HISTORY]: AttendanceEventHistory,
  [WidgetType.LIVE_SUPPORT]: LiveSupportOption,
  [WidgetType.LIVE_SUPPORT_CALL_BACK]: RequestCallBack,
  [WidgetType.WORKFLOW_ACTION_COMPLETED]: WorkflowActionCompletedView,
};
