import React, { useState } from 'react';
import { RequestsCard, RequestsCardNoRequestsText, RequestsCardType, StringDateRange } from '@amzn/time-off-common-ui';
import { DateTime } from 'luxon';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { OutputTimeOffRequest } from '@amzn/taa-rtk-query-client';
import { Button, ButtonSize } from '@amzn/stencil-react-components/button';

export interface TimeoffRequestsViewProps extends WidgetViewProps {}

const TimeoffRequestsView: WidgetView = ({ widgetInfo, employeeInfo }: TimeoffRequestsViewProps) => {
  const [showPastRequests, setShowPastRequests] = useState<boolean>(false);
  const upcomingDateRange: StringDateRange = {
    startDate: DateTime.now().toISODate(),
    endDate: DateTime.now().plus({ days: 15 }).toISODate(),
  };
  const pastDateRange: StringDateRange = {
    startDate: DateTime.now().minus({ days: 15 }).toISODate(),
    endDate: DateTime.now().toISODate(),
  };
  const onClickTimeoffRequest = (timeoffRequest: OutputTimeOffRequest) => {
    window.open(`/time/request/tor/${timeoffRequest.timeOffRequestId}`, '_blank');
  };
  const onClickViewAll = () => {
    window.open(`/time/history`, '_blank');
  };
  const toggleShowPastRequests = () => {
    showPastRequests ? setShowPastRequests(false) : setShowPastRequests(true);
  };
  return (
    <QueryClientProvider client={new QueryClient()}>
      <RequestsCard
        employeeId={employeeInfo.employeeId}
        type={RequestsCardType.UPCOMING}
        emptyCardText={RequestsCardNoRequestsText.NO_UPCOMING_REQUESTS}
        dateRange={upcomingDateRange}
        timeOffRequestOnClick={onClickTimeoffRequest}
        countryCode={employeeInfo.countryCode}
        noOfRequestsToDisplay={5}
        displayViewAllButton={true}
        displayViewMoreButton={false}
        viewAllRequestsOnClick={onClickViewAll}
      />
      {!showPastRequests ? (
        <Button size={ButtonSize.Small} dataTestId="add-time-btn" onClick={toggleShowPastRequests}>
          Show Past requests
        </Button>
      ) : (
        <RequestsCard
          employeeId={employeeInfo.employeeId}
          type={RequestsCardType.PAST}
          emptyCardText={RequestsCardNoRequestsText.NO_PAST_REQUESTS}
          dateRange={pastDateRange}
          timeOffRequestOnClick={onClickTimeoffRequest}
          countryCode={employeeInfo.countryCode}
          noOfRequestsToDisplay={5}
          displayViewAllButton={true}
          displayViewMoreButton={false}
          viewAllRequestsOnClick={onClickViewAll}
        />
      )}
    </QueryClientProvider>
  );
};

export default TimeoffRequestsView;
