import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'universal-cookie';

import { STAGE } from 'src/constants/StageConfig';

enum AtozClientId {
  ATOZ_TAAPI_SERVICE = 'ATOZ_TAAPI_SERVICE',
  ATOZ_TIMEOFF_SERVICE = 'ATOZ_TIMEOFF_SERVICE',
}

const ATOZ_HOSTS: Record<STAGE, string> = Object.freeze({
  [STAGE.PROD]: 'https://atoz-api.amazon.work',
  [STAGE.BETA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.ALPHA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.GAMMA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.TEST]: 'http://localhost:8080/gateway',
});

const ATOZ_TIMEOFF_SERVICE_PROVIDED_ENDPOINTS = new Set(['getEmployeesV3']);

const baseQuery = fetchBaseQuery();

/** Defines the RTK query to be used to call TAAPI */
export class TimeOffCommonUiQuery {
  private static currentEmployee?: string;
  private readonly stage: STAGE;
  private readonly baseQuery: typeof baseQuery;

  public static setCurrentEmployeeId = (employeeId: string): void => {
    TimeOffCommonUiQuery.currentEmployee = employeeId;
  };

  constructor(stage: STAGE) {
    this.stage = stage;

    const baseUrl = ATOZ_HOSTS[this.stage];
    this.baseQuery = fetchBaseQuery({
      baseUrl,
      /*
        Includes auth cookies in requests.
        This is needed for prod requests to be approved by the AtoZDelegator
      */
      credentials: 'include',
    });
  }

  public query: typeof baseQuery = async (args, api, extraOptions) => {
    if (typeof args !== 'string') {
      // Init request values if not present
      const headers: Record<string, string> = (args.headers as Record<string, string>) || {};
      args.method = args.method || 'GET';
      args.params = args.params || {};

      // // Add custom headers
      headers['Content-Type'] = 'application/json';
      headers['x-atoz-client-id'] = this.getAtozClientId(api.endpoint);
      if (this.stage !== STAGE.PROD) {
        headers['Authorization'] = `Bearer ${new Cookies().get('atoz-oauth-token')}`;
      }
      args.headers = headers;

      // Set spoofed user so that the correct actor is being used.
      // This will also forbid non get requests in production when spoofing.
      // https://w.amazon.com/bin/view/A-to-Z_Framework_Team/AtoZApiGateway#HIneedtomakeaspoofingrequestforadifferentemployee2ChowdoIdothat3F
      const currentEmployeeIsSet = TimeOffCommonUiQuery.currentEmployee !== undefined;
      if (!currentEmployeeIsSet) {
        throw new Error('Current Employee is not set. This must be set before using TimeOffCommonUiQuery');
      } else {
        args.params['testas'] = TimeOffCommonUiQuery.currentEmployee;
      }
    }

    // Make the API request
    const result = await this.baseQuery(args, api, extraOptions);

    return result;
  };

  /**
   * The id of the tenant to query.
   * Learn more [here](https://console.harmony.a2z.com/atoz-developer-program/developers/api_onboarding/#onboarding-sim)
   */
  private getAtozClientId(endpointName: string): AtozClientId {
    return ATOZ_TIMEOFF_SERVICE_PROVIDED_ENDPOINTS.has(endpointName)
      ? AtozClientId.ATOZ_TIMEOFF_SERVICE
      : AtozClientId.ATOZ_TAAPI_SERVICE;
  }
}
